/* Custom theme below */

$primary-color:             #1b1d1f;
$secondary-color:           #0cb4ce;
$text-color:                #777777;
$background-color:          #ffffff;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color
)