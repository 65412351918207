/* Custom styles below */

//Reset
body {
  font-family: $font-family-base;
}
.is-h1, .is-h2, .is-h3, .is-h4, .is-h5, .is-h6, h1, h2, h3, h4, h5, h6, p {
  line-height: 1.2;
}

h1, .is-h1 {
  font-weight: 100;
}

.form-control {
  border-color: $border-color;
  color: $primary-color !important;
}

.btn {
  padding-top: .8rem;
  padding-bottom: .8rem;
}

.fixed-bottom,
.fixed-top {
  z-index: 10000;
}

.is-home {
  h1 {
    font-size: 4.5rem;
    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }
  }
}

.row-overlay:after {
  background-color: rgba(0,0,0,.7);
}

.navbar-header {
  height: $menu-sticky-height;
  border-bottom: 0;
  -webkit-box-shadow: 0 4px 10px -10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 4px 10px -10px rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 10px -10px rgba(0, 0, 0, 0.6);

  .navbar-brand {
    height: 30px;
    @include transition(100ms linear);
  }

  .navbar-nav {
    text-transform: uppercase;

    a.nav-link {
      @include transition($ui-transition);

      &:hover {
        text-decoration: none !important;
        color: lighten( $text-color, 10%);
      }
    }

    @include media-breakpoint-up(lg) {
      .nav-item {
        margin: 0 .5rem;

        &:after {
          content: '';
          display: block;
          width: 0%;
          height: 2px;
          background: $secondary-color;
          @include transition(width 500ms ease-in-out);
        }
        &.active, &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    @include transition(100ms linear);
  }

  .logo-light {
    display: none;
  }

  &.at-top {
    height: $menu-height;

    .navbar-brand {
      height: 40px;
    }
  }

  .is-home & {
    .navbar-primary {
      background: transparent !important;
      @include transition(100ms linear);

      &.show, &.collapsing {
        background: $white !important;
      }
    }

    &.at-top {
      .logo-light {
        display: none;
      }

      .logo-dark {
        display: flex;
      }

      @include media-breakpoint-up(lg) {
        background-color: transparent !important;

        a.nav-link {
          color: $background-color !important;
        }

        .logo-light {
          display: flex;
        }

        .logo-dark {
          display: none;
        }
      }
    }
  }
}

.bb-site-wrapper {
  .main {
    padding-top: $menu-height;

    .is-home & {
      padding-top: 0;
    }

    //cloud
    .cloudcontentset {
      .btn-row-settings {
        top: unset;
        bottom: 54px;
      }
    }

    .cloudcontentset ~ .cloudcontentset {
      .btn-row-settings {
        top: 0;
        bottom: unset;
      }
    }
  }
}

.figure {
  .zoom-in {

    img {
      @include transition($ui-transition);
    }

    &:hover {
      img {
        @include scale(1.2);
      }
    }
  }

  &.image-fullwidth, &.image-resize {
    .contentwrapper .image-overlay {
      background-color: rgba($primary-color, .5);
    }
  }

  figcaption {
    p {
      line-height: 1;
    }
  }
}

.footer {
  border-top: 0;

  .social-list {
    margin-bottom: 0;
  }
}

img {
  &.grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: 800ms ease;
    transition-property: filter;
    -webkit-transition-property: -webkit-filter;
    -moz-transition-property: -moz-filter;

    &:hover {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}

//cloud
.is-desktop .cloud-edit.add-padding .navbar {
  padding-left: 96px !important;
}

// Animation
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

.text-animated,
.image-animated {
  opacity: 0;
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;

  &.delay-1s {
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
  }

  &.delay-2s {
    -webkit-animation-delay: 800ms;
    animation-delay: 800ms;
  }

  &.delay-3s {
    -webkit-animation-delay: 1200ms;
    animation-delay: 1200ms;
  }
}

.photo-wall {
  flex-flow: wrap;

  &-item {
    flex-shrink: 0;
    background-size: cover;
    overflow: hidden;
    padding: 0;

    img {
      @include transition(opacity 500ms ease-in-out);
    }

    &:hover {
      img {
        opacity: 0;
      }
    }
  }

  li {
    &:nth-child(1) {
      background-image: url("../img/cedric_2.jpg");
    }

    &:nth-child(2) {
      background-image: url("../img/sylvain_2.jpg");
    }

    &:nth-child(3) {
      background-image: url("../img/beatrice_2.jpg");
    }
  }
}

.photo-text-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}
